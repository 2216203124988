import { tv } from 'tailwind-variants'

export const container = tv({
  base: ['flex', 'flex-col', 'items-center', 'gap-12', 'tablet:gap-16']
})

export const svg = tv({
  base: ['w-32', 'h-32', '[&_path]:fill-secondary-500']
})

export const title = tv({
  base: ['text-black-800', 'text-xl', 'font-bold', 'text-center']
})

export const description = tv({
  base: [
    'text-black-700',
    'text-sm',
    'text-center',
    'max-w-[560px]',
    '[&_strong]:text-sm',
    '[&_strong]:text-black-800'
  ]
})

export const row = tv({
  base: ['flex', 'gap-4', 'items-center', '[&_label]:mb-0']
})

export const agreeText = tv({
  base: ['text-black-700', 'text-xs']
})

export const buttonStyles = tv({
  base: ['w-[224px]']
})

export const resendEmail = tv({
  base: [
    'text-black-550',
    'font-bold',
    'text-xs underline',
    'disabled:no-underline'
  ]
})
