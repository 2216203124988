import { KEY_STORAGE_TMX } from 'settings/constants'

export function getSessionIdTmxStorage(): string {
  return (
    window.localStorage.getItem(KEY_STORAGE_TMX) ||
    '85bb8f2-c399-4ae9-9c70-70f65da271f6'
  )
}

export function saveSessionIdTmxStorage(sessionId: string): void {
  window.localStorage.setItem(KEY_STORAGE_TMX, sessionId)
}
