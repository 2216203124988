import { isDocumentValid } from 'utils/functions'
import { z } from 'zod'

export const passwordRecoveryModalSchema = z.object({
  customerDocument: z
    .string({ required_error: 'Campo é obrigatório.' })
    .refine((document) => {
      return isDocumentValid(document)
    }, 'O CPF ou CNPJ informado é invalido.')
})
