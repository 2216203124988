import { zodResolver } from '@hookform/resolvers/zod'
import { dataLayerEventLogin, useUser, useVisitor } from '@k_frontend/core'
import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError,
  RegistrationInconsistenciesError
} from 'errors'
import { useRouter } from 'next/router'
import { useToast } from 'providers/Toast'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Kernel from 'settings/kernel'
import { handleStore } from 'utils/handleStore'
import { redirect } from 'utils/redirect'
import {
  makeLoginRequest,
  sendToDatalayer
} from './FormLoginContainer.functions'
import { formLoginSchema } from './FormLoginContainer.schema'
import { useLoginStore } from './FormLoginContainer.store'
import {
  ConcretizeLoginProps,
  FormLoginValues
} from './FormLoginContainer.types'

export function useFormLogin() {
  const router = useRouter()
  const {
    setLogin,
    setPassword,
    setButtonIsLoading,
    loginFormErrors,
    setLoginFormErrors,
    isOpenAccountRecoveryModal,
    setIsOpenAccountRecoveryModal,
    isOpenRecoveryPasswordModal,
    setIsOpenRecoveryPasswordModal,
    loginPasswordVisible,
    setLoginPasswordVisible,
    lifeProofModal,
    setLifeProofModal,
    typeLogin,
    setIsRegistrationInconsistenciesModal,
    isRegistrationInconsistenciesModal
  } = useLoginStore()
  const { setClientName, setClientId, setIsPrime, setSession, setClientEmail } =
    useUser()
  const { init } = useVisitor()
  const { showError } = useToast()
  const [titlePasswordRecoveryModal, setTitlePasswordRecoveryModal] =
    useState<string>('ESQUECEU SUA SENHA DE ACESSO?')
  const [concretizeLoginProps, setConcretizeLoginProps] = useState(
    {} as ConcretizeLoginProps
  )
  const [agreementModalOpen, setAgreementModalOpen] = useState(false)
  const [redirectUri, setRedirectUri] = useState<string>('')

  const form = useForm<FormLoginValues>({
    resolver: zodResolver(formLoginSchema),
    mode: 'onChange'
  })

  const handleChangeLoginInput = useCallback(
    (event) => {
      form.setValue('login', event.target.value.replace(/\s/g, ''))
    },
    [form]
  )

  const { isPending, mutateAsync } = makeLoginRequest()

  const concretizeLogin = useCallback(
    ({
      firstName,
      customerId,
      customerSession,
      isPrimeCustomer,
      needToUpdateData,
      maxAgeCookie,
      customerEmail
    }: ConcretizeLoginProps) => {
      setClientName(firstName)
      setClientId(customerId, maxAgeCookie)
      setSession(customerSession, maxAgeCookie)
      setClientEmail(customerEmail)

      if (isPrimeCustomer > 0) {
        setIsPrime(true, maxAgeCookie)
      }

      if (needToUpdateData) {
        localStorage.setItem('needsUpdatePJ', 'true')
      }

      sendToDatalayer(typeLogin)

      redirect(router)
    },
    [
      setClientName,
      setClientId,
      setSession,
      setClientEmail,
      setIsPrime,
      dataLayerEventLogin,
      redirect
    ]
  )

  const onSubmit = async ({ login, password }: FormLoginValues) => {
    setPassword(password)
    setLogin(login)
    setButtonIsLoading(true)

    setLoginFormErrors({
      ...loginFormErrors,
      errorMessage: '',
      errorClick: false,
      errorFunction: '',
      loginType: 'form'
    })

    try {
      const {
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        updateConsentForm,
        customerEmail
      } = await mutateAsync({ login, password })

      if (customerId) {
        await init(Kernel, customerId, customerSession)
      }

      if (!updateConsentForm)
        return concretizeLogin({
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData,
          customerEmail
        })

      setConcretizeLoginProps({
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        customerEmail
      })
      setAgreementModalOpen(true)
    } catch (error) {
      if (error instanceof ExpectedError) {
        setLoginFormErrors({
          ...loginFormErrors,
          errorMessage: error.message,
          errorClick: false,
          errorFunction: 'genericError',
          loginType: 'form'
        })

        showError(error.message)
      }

      if (error instanceof LifeProofError) {
        setLifeProofModal({
          errorType: error.errorType,
          isOpen: true,
          lifeProofURL: error.urlLinkLifeProof,
          storeCode: handleStore()
        })
      }

      if (error instanceof RecoveryAccountError) {
        setLoginFormErrors({
          ...loginFormErrors,
          userLogin: error.email,
          userPhone: error.mobilePhone,
          errorMessage: error.message,
          errorClick: true,
          errorFunction: 'inactiveAccount',
          recoveryHashLogin: error.recoveryHash,
          loginType: 'form'
        })

        setIsOpenAccountRecoveryModal(true)
        return
      }

      if (error instanceof NonExistentRegistration) {
        showError(error.message)

        setTimeout(() => {
          router.push('/cadastro')
        }, 3000)
      }

      if (error instanceof RegistrationInconsistenciesError) {
        setIsRegistrationInconsistenciesModal(true)
      }
    } finally {
      setButtonIsLoading(false)
    }
  }

  useEffect(() => {
    const path = window.location.search
    if (path) setRedirectUri(path)
  }, [])

  return {
    form,
    onSubmit,
    loginFormErrors,
    isOpenAccountRecoveryModal,
    setIsOpenAccountRecoveryModal,
    isOpenRecoveryPasswordModal,
    setIsOpenRecoveryPasswordModal,
    loginPasswordVisible,
    setLoginPasswordVisible,
    isPending,
    titlePasswordRecoveryModal,
    setTitlePasswordRecoveryModal,
    concretizeLoginProps,
    concretizeLogin,
    agreementModalOpen,
    setAgreementModalOpen,
    setConcretizeLoginProps,
    handleChangeLoginInput,
    redirectUri,
    lifeProofModal,
    setLifeProofModal,
    setIsRegistrationInconsistenciesModal,
    isRegistrationInconsistenciesModal
  }
}
