const { axiosException } = require('../exception/axios')
const {
  listingResponseOferta,
  listingResponseBannerOferta
} = require('../models/listing')
const { axios } = require('../services/axios')
const {
  BACKEND_LISTAGEM_OFERTA__URL,
  BACKEND_BANNER_OFERTA__URL,
  BACKEND_BANNERS_LISTING,
  BACKEND_BANNERS_BUSCA
} = require('../settings/kernel')

const self = {}

self.getProdutosOferta = async (params) => {
  try {
    const {
      campanha,
      pagina,
      marcas,
      limite,
      ordem,
      min,
      max,
      estrelas,
      desconto_min,
      desconto_max,
      dep,
      sec,
      string
    } = params

    const campanhaString = campanha ? `campanha=${campanha}&` : ''
    const paginaString = pagina ? `pagina=${pagina}&` : 'pagina=1&'
    const limiteString = limite ? `limite=${limite}&` : 'limite=20&'
    const marcasString = marcas
      ? `marcas=${marcas.replace('[', '').replace(']', '')}&`
      : ''
    const ordemString = ordem ? `ordem=${ordem}&` : ''
    const minString = min ? `valor_min=${min}&` : ''
    const maxString = max ? `valor_max=${max}&` : ''
    const estrelasString = estrelas ? `estrelas=${estrelas}&` : ''
    const depString = dep ? `dep=${dep}&` : ''
    const secString = sec ? `sec=${sec}&` : ''
    const descontoMinString = desconto_min
      ? `desconto_minimo=${desconto_min}&`
      : ''
    const descontoMaxString = desconto_max
      ? `desconto_maximo=${desconto_max}&`
      : ''
    const buscaString = string ? `string=${string}&` : ''

    const url = `${BACKEND_LISTAGEM_OFERTA__URL}${campanhaString}${limiteString}${paginaString}${marcasString}${ordemString}${minString}${maxString}${estrelasString}${depString}${secString}${descontoMinString}${descontoMaxString}${buscaString}app=1`

    const { data } = await axios.get(url)
    const request = {
      minDiscount: descontoMinString,
      maxDiscount: descontoMaxString,
      minPrice: minString,
      maxPrice: maxString
    }

    return listingResponseOferta(data, request)
  } catch (error) {
    throw axiosException(error, 'LISTING OFFER')
  }
}

self.getBannerOferta = async (nome, ENDPOINT = false) => {
  try {
    const nome2 = nome ? `/${nome}` : ''

    const uri = ENDPOINT ? ENDPOINT : BACKEND_BANNER_OFERTA__URL
    const url = `${uri}${nome2}`

    const { data } = await axios.get(url)

    return listingResponseBannerOferta(data)
  } catch (error) {
    throw axiosException(error, 'LISTING BANNER OFERTA')
  }
}

self.getParamsFromQueryString = (queryString) => {
  const result = {}

  const params = new URLSearchParams(queryString.slice(1))
  const entries = params.entries()
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

self.getParamsFromSlug = (slugString, type = '') => {
  const slugArray = slugString.split('/')
  let result = {}

  switch (type) {
    case 'offer':
      result = {
        campanha: slugArray[2] || ''
      }
      break
    default:
      result = {
        dep: slugArray[1] || '',
        sec: slugArray[2] || '',
        cat: slugArray[3] || ''
      }
      break
  }

  return result
}

self.formatFilterListToUrlParam = (filterList) => {
  return JSON.stringify(
    Object.values(filterList).map((item) => item.map((item) => item.code))
  )
}

self.formatBrandListToUrlParam = (filterList) => {
  const formatedList = Object.values(filterList).map((item) =>
    item.map((item) => item.code)
  )

  return JSON.stringify(formatedList[0] || [])
}

self.formatSelectedRangeToUrlParam = (filter) => {
  const min = filter[0].minPrice
  const max = filter[0].maxPrice
  return `min=${min}&max=${max}`
}

self.replaceUrlParams = (params, shouldPush = false) => {
  if (shouldPush) {
    window.history.pushState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  } else {
    window.history.replaceState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  }
}

self.getBannersCategories = async (categoryCode) => {
  try {
    const data = await axios.get(`${BACKEND_BANNERS_LISTING}${categoryCode}`)
    return data
  } catch (error) {
    throw axiosException(error, 'GET BANNER CATEGORIES LISTAGEM')
  }
}

self.getBannersSearch = async (search) => {
  try {
    const data = await axios.get(
      `${BACKEND_BANNERS_BUSCA}${encodeURIComponent(search)}`
    )
    return data
  } catch (error) {
    throw axiosException(error, 'GET BANNER SEARCH LISTAGEM')
  }
}

module.exports = self
