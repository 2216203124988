import { dataLayerEventLogin } from '@k_frontend/core'
import { useMutation } from '@tanstack/react-query'
import { useReCaptcha } from 'next-recaptcha-v3'
import { Login } from 'services/login/login'
import { handleStore } from 'utils/handleStore'
import { getSessionIdTmxStorage } from 'utils/sessionIdTmxStorage'
import { DatalayerMethod, LoginFormProps } from './FormLoginContainer.types'
import { LoginWithSocialAccountProps } from './LoginWithSocialAccount/LoginWithSocialAccount.types'

export function makeLoginRequest() {
  const { executeRecaptcha } = useReCaptcha()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ login, password }: LoginFormProps) => {
      const captchaToken = await executeRecaptcha('login')
      const serviceLogin = new Login()
      return await serviceLogin.doLogin({
        password: password,
        login: login,
        captcha: captchaToken,
        tmxSession: getSessionIdTmxStorage(),
        storeCode: handleStore()
      })
    }
  })

  return { mutateAsync, isPending }
}

export function makeSocialLoginRequest() {
  const { executeRecaptcha } = useReCaptcha()

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      socialNetwork,
      tokenNetwork
    }: LoginWithSocialAccountProps) => {
      const captchaToken = await executeRecaptcha('socialLogin')
      const serviceLogin = new Login()

      return await serviceLogin.doLoginWithSocialAccount({
        accessToken: tokenNetwork,
        captcha: captchaToken,
        socialAccountNetwork: socialNetwork,
        storeCode: handleStore(),
        tmxSession: getSessionIdTmxStorage()
      })
    }
  })
  return {
    mutateAsync
  }
}

export function sendToDatalayer(method: DatalayerMethod) {
  dataLayerEventLogin(method)
}
