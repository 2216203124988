import { IconClose } from '@k_frontend/icons'
import { useCallback } from 'react'
import { ModalProps } from './Modal.types'

export function Modal({
  isVisible,
  onCloseButton,
  title,
  children
}: ModalProps) {
  const handleCloseModal = useCallback((e) => {
    if (e.target.id === 'modalWrapper') onCloseButton()
  }, [])

  if (!isVisible) return null

  return (
    <div
      className='fixed inset-0 bg-black-800 bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-10'
      onClick={handleCloseModal}
      onKeyUp={handleCloseModal}
      id='modalWrapper'
    >
      <div className='min-w-[320px] max-w-[736px] w-full'>
        <div className='bg-white-500 p-16 rounded-4'>
          <div className='flex items-center justify-between'>
            <span className='font-poppins text-sm font-bold text-black-800 desktop:text-xl'>
              {title}
            </span>
            <button
              className='text-secondary-500 text-xl'
              type='button'
              onClick={onCloseButton}
            >
              <IconClose width='24' height='24' />
            </button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}
