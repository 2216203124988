import { usePasswordRecoveryModalStore } from '../PasswordRecoveryModal.store'
import { ChoiceStepProps } from './ChoiceStep.types'
import { EmailSenderButton } from './EmailSenderButton'
import { SmsSenderButton } from './SmsSenderButton'

export function ChoiceStep({
  customerEmail,
  customerPhone,
  sendMfaMethod
}: ChoiceStepProps) {
  const { enableEmailButton, enableSmsButton, buttonIsLoading } =
    usePasswordRecoveryModalStore()

  return (
    <div className='flex flex-col items-center'>
      <p className='text-sm font-normal text-black-800 text-center mt-16'>
        Para você acessar sua conta, enviaremos um código para seu celular ou
        e-mail cadastrado, selecione a opção desejada:
      </p>

      <div className='grid gap-8 mt-16 grid-cols-2 max-w-[448px]'>
        <EmailSenderButton
          sendMfaMethod={sendMfaMethod}
          customerEmail={customerEmail}
          disableEmailButton={enableEmailButton}
          isLoading={buttonIsLoading}
        />
        <SmsSenderButton
          sendMfaMethod={sendMfaMethod}
          customerPhone={customerPhone}
          disableSmsButton={enableSmsButton}
          isLoading={buttonIsLoading}
        />
      </div>
    </div>
  )
}
