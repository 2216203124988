import { Button } from '@kbm/button'
import { Icon, warning } from '@kbm/icons'

export function RegistrationInconsistenciesModal({
  handleCloseModal
}: { handleCloseModal: () => void }) {
  return (
    <div className='flex flex-col text-center items-center justify-center'>
      <div>
        <Icon
          codepoint={warning}
          className='text-secondary-500 text-3xl mr-8 items-center'
          fill
        />
        <span className='flex text-center text-lg font-bold text-black-800 uppercase'>
          Precisamos confirmar seus dados
        </span>
      </div>
      <div>
        <span className='text-center text-sm font-normal text-black-700 my-12'>
          Por favor, entre em contato com o
        </span>
        <a
          className='text-secondary-500 font-bold text-sm cursor-pointer hover:underline'
          href='/atendimento'
          rel='noreferrer'
        >
          &nbsp; nosso atendimento &nbsp;
        </a>
        <span className='text-center text-sm font-normal text-black-700 my-12'>
          para regularizar sua conta
        </span>
      </div>
      <Button
        variant={{
          color: 'secondary',
          full: true,
          type: 'solid',
          size: '40'
        }}
        className='w-full max-w-[328px] my-12'
        onClick={() => handleCloseModal()}
      >
        Fechar
      </Button>
    </div>
  )
}
