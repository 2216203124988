import { tv } from 'tailwind-variants'

export const buttonWrapper = tv({
  base: [
    'flex',
    'flex-wrap',
    'gap-12',
    'items-center',
    'justify-center',
    'min-h-40',
    'min-w-80',
    'bg-secondary-500',
    'rounded-4',
    'p-8',

    'font-bold',
    'text-base',
    'text-white-500',

    '[&_svg]:w-24',
    '[&_svg]:h-24',
    '[&_svg]:fill-white-500',

    'disabled:bg-black-550'
  ],
  variants: {
    ghost: {
      true: [
        'bg-transparent',
        'border',
        'border-solid',
        'border-secondary-500',
        'text-secondary-500',
        '[&_svg]:fill-secondary-500',

        'disabled:bg-transparent',
        'disabled:border-black-550',
        'disabled:text-black-550',
        '[&_svg]:disabled:fill-black-550'
      ]
    },
    fullWidth: {
      true: 'w-full'
    },
    size: {
      lg: 'w-[320px]',
      md: 'w-[200px]',
      sm: 'w-[120px]'
    }
  }
})

export const description = tv({
  base: ['text-black-700', 'text-sm', 'text-center']
})
