import styled, { css, DefaultTheme } from 'styled-components'

import { HeadingProps } from './Heading.types'

const wrapperModifiers = {
  lineLeft: (theme: DefaultTheme) => css`
    padding-left: ${theme.spacing.xs};
    border-left: 2px solid ${theme.colors.primary(500)};
  `,
  lineBottom: (theme: DefaultTheme) => css`
    position: relative;
    margin-bottom: ${theme.spacing.md};
    &::after {
      position: absolute;
      left: 0;
      bottom: 0px;
      content: '';
      width: 5rem;
      border: 2px solid ${theme.colors.primary(500)};
    }
  `
}

export const Wrapper = styled.h2<HeadingProps>`
  ${({ theme, color, lineLeft, lineBottom }) => css`
    color: ${theme.colors[color!](500)};
    ${theme.font(20, 30, 700, 'Uppercase')};
    ${lineLeft && wrapperModifiers.lineLeft(theme)}
    ${lineBottom && wrapperModifiers.lineBottom(theme)}
    @media (min-width: ${theme.breakpoints.minTablet}) {
      ${theme.font(24, 34, 700, 'Uppercase')};
    }
  `}
`
