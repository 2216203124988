import { useEffect, useState } from 'react'

export function countdown(time: number) {
  const [counter, setCounter] = useState<number>(time)

  function resetCountdown() {
    setCounter(time)
  }

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counter])

  return { counter, resetCountdown }
}
