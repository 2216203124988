export type AccountRecoveryModalProps = {
  customerEmail: string
  customerPhone: string
  hash: string
}

export enum RecoveryStepEnum {
  DOCUMENT_STEP = 'cpfOrCnpjStep',
  METHOD_CHOICE_STEP = 'choiceStep',
  SUCCESS_STEP = 'successStep'
}

export enum MethodSenderMfaCodeEnum {
  NOT_SELECTED,
  EMAIL,
  SMS
}

export type UseAccountRecoveryModalStoreProps = {
  enableEmailButton: boolean
  enableSmsButton: boolean
  buttonSmsIsLoading: boolean
  buttonEmailIsLoading: boolean
  setEnableEmailButton: (enableEmail: boolean) => void
  setEnableSmsButton: (enableSms: boolean) => void
  setButtonSmsIsLoading: (buttonSMSIsLoading: boolean) => void
  setButtonEmailIsLoading: (buttonEmailIsLoading: boolean) => void
}
