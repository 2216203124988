import { Button } from '@kbm/button'
import { Icon, warning } from '@kbm/icons'
import { useQRCode } from 'next-qrcode'
import { useMemo } from 'react'
import { DESKTOP_STORE, MOBILE_STORE } from 'settings/kernel'
import { LifeProofModalProps } from './LifeProofModal.types'

export function LifeProofModal({
  errorType,
  handleCloseModal,
  lifeProofURL,
  storeCode
}: LifeProofModalProps) {
  const { Image } = useQRCode()

  const modalMessageToRender = useMemo(() => {
    if (errorType === '002' && storeCode === MOBILE_STORE) {
      const title = 'Precisamos confirmar seus dados'
      const modalBody = (
        <div className='flex flex-col items-center'>
          <p className='text-center text-sm font-semibold text-black-700 mt-12 max-w-[420px]'>
            Clique em continuar e siga os próximos passos.
            <br />
            Fique atento! Você receberá a resposta por e-mail.
          </p>
          <br />
          <br />
          <span className='text-xxs text-black-700'>
            O processo está em conformidade com a{' '}
            <b className='text-xxs text-secondary-500 font-semibold underline'>
              LGPD
            </b>
            ,{' '}
            <b className='text-xxs text-secondary-500 font-semibold underline'>
              ANPD
            </b>{' '}
            e nossa{' '}
            <a
              className='text-xxs text-secondary-500 font-semibold underline'
              href='/privacidade'
              target='_blank'
              rel='noreferrer'
            >
              política de privacidade.
            </a>
          </span>
          <br />
          <div className='w-full flex flex-col items-center tablet:flex-row tablet:max-w-[460px] tablet:w-full'>
            <Button
              variant={{
                color: 'secondary',
                full: true,
                type: 'outlined',
                size: '40'
              }}
              className='w-full max-w-[328px] mb-12 tablet:mr-12'
              onClick={() => handleCloseModal()}
            >
              Fechar
            </Button>
            <Button
              variant={{
                color: 'secondary',
                full: true,
                type: 'solid',
                size: '40'
              }}
              className='w-full max-w-[328px] mb-12'
              onClick={() => {
                window.location.href = lifeProofURL
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      )

      return {
        title,
        modalBody
      }
    }

    if (errorType === '002' && storeCode === DESKTOP_STORE) {
      const title = 'Precisamos confirmar seus dados'
      const modalBody = (
        <div className='grid grid-cols-3'>
          <div className='col-span-1 mr-24'>
            <Image
              text={lifeProofURL || 'https://kabum.com.br/privacidade'}
              options={{
                type: 'image/jpeg',
                quality: 0.3,
                errorCorrectionLevel: 'M',
                margin: 3,
                scale: 4,
                width: 200
              }}
            />
          </div>
          <div className='col-span-2 text-start'>
            <p className='text-start text-sm font-semibold text-black-700 mt-12 max-w-[420px]'>
              Leia o QR Code e siga os próximos passos pelo seu celular.
              <br />
              Fique atento! Você receberá a resposta por e-mail.
            </p>
            <br />
            <span className='text-xxs text-black-700'>
              O processo está em conformidade com a{' '}
              <b className='text-xxs text-secondary-500 font-semibold underline'>
                LGPD
              </b>
              ,{' '}
              <b className='text-xxs text-secondary-500 font-semibold underline'>
                ANPD
              </b>{' '}
              e nossa{' '}
              <a
                className='text-xxs text-secondary-500 font-semibold underline'
                href='/privacidade'
                target='_blank'
                rel='noreferrer'
              >
                política de privacidade.
              </a>
            </span>
            <br />
            <br />
            <div className='flex flex-col'>
              <a
                className='text-black-700 font-normal text-[8px] cursor-pointer underline'
                href={lifeProofURL}
              >
                ou continue pelo computador &gt;
              </a>
              <span className='text-start text-[8px] font-normal text-black-700 mt-12 max-w-[420px]'>
                Certifique-se de ter uma câmera ou webcam para prosseguir.
              </span>
            </div>
          </div>
        </div>
      )

      return {
        title,
        modalBody
      }
    }

    if (errorType === '011') {
      const title = 'Estamos confirmando os seus dados'
      const modalBody = (
        <div className='flex flex-col items-center'>
          <span className='text-center text-sm font-normal text-black-700 my-12'>
            Seu cadastro ainda está sendo validado. Aguarde o retorno por email
            ou tente novamente após 24h. 
          </span>
          <Button
            variant={{
              color: 'secondary',
              full: true,
              type: 'solid',
              size: '40'
            }}
            className='w-full max-w-[328px] mb-12 tablet:mr-12'
            onClick={() => handleCloseModal()}
          >
            Fechar
          </Button>
        </div>
      )

      return {
        title,
        modalBody
      }
    }

    if (errorType === '012') {
      const title = 'Precisamos confirmar seus dados'
      const modalBody = (
        <div className='flex flex-col items-center'>
          <span className='text-center text-sm font-normal text-black-700 my-12'>
            Precisamos validar sua titularidade mas nosso sistema está fora do
            ar, por favor, tente novamente mais tarde.
          </span>
          <Button
            variant={{
              color: 'secondary',
              full: true,
              type: 'solid',
              size: '40'
            }}
            className='w-full max-w-[328px] mb-12 tablet:mr-12'
            onClick={() => handleCloseModal()}
          >
            Fechar
          </Button>
        </div>
      )

      return {
        title,
        modalBody
      }
    }

    if (errorType === '013') {
      const title = 'Conta empresarial bloqueada'
      const modalBody = (
        <div className='flex flex-col items-center'>
          <span className='text-center text-sm font-normal text-black-700 my-12'>
            Sua conta PJ está temporariamente indisponível em nosso sistema.
            Para resolver essa situação, clique no botão abaixo:
          </span>
          <a
            className='flex w-[328px] h-40 bg-secondary-500 text-white-500 rounded-4 items-center justify-center text-sm font-bold uppercase'
            href={lifeProofURL}
            rel='noreferrer'
          >
            Desbloquear conta
          </a>
        </div>
      )

      return {
        title,
        modalBody
      }
    }
  }, [errorType])

  return (
    <div className='flex flex-col items-center text-center'>
      <div className='flex flex-col items-center'>
        <Icon
          codepoint={warning}
          className='text-secondary-500 text-3xl mr-8 items-center'
          fill
        />
        <span className='flex text-lg font-bold text-black-800 uppercase'>
          {modalMessageToRender?.title}
        </span>
      </div>
      {modalMessageToRender?.modalBody}
    </div>
  )
}
