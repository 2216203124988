import { useMutation } from '@tanstack/react-query'
import { ExpectedError } from 'errors'
import { useToast } from 'providers/Toast'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AgreementAds } from 'services/login/agreementAds'
import { Notifications } from 'services/notifications/notifications'
import { PostMFACodeProps } from './AgreementModal.types'

export function useAgreementModal({
  isOpen,
  setIsOpen,
  concretizeLogin,
  concretizeLoginProps
}) {
  const [isChecked, setIsChecked] = useState(true)
  const [counter, setCounter] = useState(15)
  const [mfaInput, setMfaInput] = useState('')
  const { showError } = useToast()

  const onChangeMfaInput = useCallback(
    (e) => {
      setMfaInput(e.target.value)
    },
    [setMfaInput]
  )

  const onChangeCheckbox = useCallback(() => {
    setIsChecked((val) => !val)
  }, [isChecked, setIsChecked])

  const { mutateAsync: postMfaCode, isPending: isPendingPostMfaCode } =
    useMutation({
      mutationFn: async ({ customerId, customerSession }: PostMFACodeProps) => {
        const mfaNotifications = new Notifications()
        return await mfaNotifications.SendMfaToEmailOrPhone({
          customerId,
          customerSession,
          sendMethod: 1
        })
      },
      onError(error) {
        if (error instanceof ExpectedError) {
          showError(error.message)
        }
      }
    })

  const {
    mutateAsync: putUserAgreement,
    isPending: isPendingPutUserAgreement
  } = useMutation({
    mutationFn: async () => {
      const agreement = new AgreementAds()
      return await agreement.putUserAgreement({
        customerId: concretizeLoginProps.customerId,
        customerSession: concretizeLoginProps.customerSession,
        newsletter: isChecked,
        mfa: mfaInput
      })
    },
    onSuccess() {
      concretizeLogin(concretizeLoginProps)
      setIsOpen(false)
    },
    onError(error) {
      if (error instanceof ExpectedError) {
        showError(error.message)
      }
    }
  })

  const generateMfaCode = useCallback(() => {
    setCounter(60)
    postMfaCode({
      customerId: concretizeLoginProps.customerId,
      customerSession: concretizeLoginProps.customerSession
    })
  }, [setCounter, concretizeLoginProps, postMfaCode])

  const shouldLoadingSubmitButton = useMemo(() => {
    return isPendingPutUserAgreement || isPendingPostMfaCode
  }, [isPendingPostMfaCode, isPendingPutUserAgreement])

  const shouldDisableresendMFAButton = useMemo(() => {
    return counter > 0 || shouldLoadingSubmitButton
  }, [counter, shouldLoadingSubmitButton])

  const shouldDisableSubmitButton = useMemo(() => {
    return mfaInput.length < 6
  }, [mfaInput])

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counter])

  useEffect(() => {
    if (isOpen)
      postMfaCode({
        customerId: concretizeLoginProps.customerId,
        customerSession: concretizeLoginProps.customerSession
      })
  }, [isOpen])

  return {
    generateMfaCode,
    isChecked,
    onChangeCheckbox,
    counter,
    shouldDisableSubmitButton,
    onChangeMfaInput,
    putUserAgreement,
    shouldLoadingSubmitButton,
    shouldDisableresendMFAButton
  }
}
