import * as S from './styles'

export default function Loader(props) {
  const { color } = props
  return (
    <S.Spinner color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </S.Spinner>
  )
}

export const LineLoader = ({
  size = 36,
  color = '#ffffff',
  thickness = 4,
  ...props
}) => {
  return (
    <S.LineLoader thickness={thickness} size={size} color={color} {...props} />
  )
}

export const KLoader = (props) => {
  const { size = 100, text, color } = props

  return (
    <>
      <S.KLoader size={size} x='0px' y='0px' viewBox='0 0 960 320'>
        <g fill={color || '#ff6500'} id='kloader1'>
          <path d='m247.5,312.7c-4.2,-1.9 -5.5,-5.5 -5.5,-14.5c0.1,-14.4 6.3,-26.7 20.5,-40.7c18.2,-17.9 44,-33 99,-57.8c11.6,-5.2 25.3,-11.5 30.6,-14c27.9,-13 41.1,-24.1 39.6,-33.2c-0.9,-5.2 -5.2,-9.9 -29.7,-32.3c-56.2,-51.2 -78,-77.9 -78.2,-95.5c0,-6.4 2.1,-9.4 9.2,-12.9c7.1,-3.6 21.7,-5.3 32.2,-3.8c30.5,4.3 97,41.3 149.1,82.9c14.9,11.8 25.5,22.1 29,28.2c4.2,7.2 6.1,16 4.8,23c-3.3,18.7 -22.7,38.7 -58.3,60.2c-11.2,6.7 -43.8,23.2 -59.3,30c-4.4,1.9 -32.7,15.6 -63,30.5c-57.8,28.3 -76.3,37 -96.8,45.2c-14.3,5.7 -18.9,6.6 -23.2,4.7z' />
        </g>
        <g fill={color || '#ff6500'} id='kloader2'>
          <path d='m403.5,312.7c-4.2,-1.9 -5.5,-5.5 -5.5,-14.5c0.1,-14.4 6.3,-26.7 20.5,-40.7c18.2,-17.9 44,-33 99,-57.8c11.6,-5.2 25.3,-11.5 30.6,-14c27.9,-13 41.1,-24.1 39.6,-33.2c-0.9,-5.2 -5.2,-9.9 -29.7,-32.3c-56.2,-51.2 -78,-77.9 -78.2,-95.5c0,-6.4 2.1,-9.4 9.2,-12.9c7.1,-3.6 21.7,-5.3 32.2,-3.8c30.5,4.3 97,41.3 149.1,82.9c14.9,11.8 25.5,22.1 29,28.2c4.2,7.2 6.1,16 4.8,23c-3.3,18.7 -22.7,38.7 -58.3,60.2c-11.2,6.7 -43.8,23.2 -59.3,30c-4.4,1.9 -32.7,15.6 -63,30.5c-57.8,28.3 -76.3,37 -96.8,45.2c-14.3,5.7 -18.9,6.6 -23.2,4.7z' />
        </g>
      </S.KLoader>
      <S.KLoaderText>{text}</S.KLoaderText>
    </>
  )
}
