import { create } from 'zustand'
import { UsePasswordRecoveryModalStoreProps } from './PasswordRecoveryModal.types'

export const usePasswordRecoveryModalStore =
  create<UsePasswordRecoveryModalStoreProps>((set) => ({
    enableEmailButton: false,
    enableSmsButton: false,
    buttonIsLoading: false,
    setEnableEmailButton: (enableEmailButton) => set({ enableEmailButton }),
    setEnableSmsButton: (enableSmsButton) => set({ enableSmsButton }),
    setButtonIsLoading: (buttonIsLoading) => set({ buttonIsLoading })
  }))
