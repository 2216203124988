import { create } from 'zustand'
import { UseAccountRecoveryModalStoreProps } from './AccountRecoveryModal.types'

export const useAccountRecoveryModalStore =
  create<UseAccountRecoveryModalStoreProps>((set) => ({
    enableEmailButton: false,
    enableSmsButton: false,
    buttonEmailIsLoading: false,
    buttonSmsIsLoading: false,
    setEnableEmailButton: (enableEmailButton) => set({ enableEmailButton }),
    setEnableSmsButton: (enableSmsButton) => set({ enableSmsButton }),
    setButtonEmailIsLoading: (buttonEmailIsLoading) =>
      set({ buttonEmailIsLoading }),
    setButtonSmsIsLoading: (buttonSmsIsLoading) => set({ buttonSmsIsLoading })
  }))
