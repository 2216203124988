import { Heading } from 'components/Heading'

import {
  IconEye,
  IconEyeClose,
  IconLocked,
  IconLoginButton,
  IconUser
} from '@k_frontend/icons'
import { Errors } from 'components/Errors'
import { KLoader } from 'components/Loader'
import { Modal } from 'components/Modal'
import { TmxProfile } from 'components/Tmx'
import Link from 'next/link'
import { useCallback, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { TMX_COMPANY_ID } from 'settings/kernel'
import theme from 'theme'
import { AccountRecoveryModal } from './AccountRecoveryModal'
import { AgreementModal } from './AgreementModal'
import { useFormLogin } from './FormLoginContainer.hook'
import {
  inputErrorStyles,
  inputLoginFormStyles
} from './FormLoginContainer.styles'
import { LifeProofModal } from './LifeProofModal'
import { LoginWithSocialAccount } from './LoginWithSocialAccount'
import { PasswordRecoveryModal } from './PasswordRecoveryModal'
import { RegistrationInconsistenciesModal } from './RegistrationInconsistenciesModal'

export function FormLogin() {
  const {
    form,
    onSubmit,
    isOpenAccountRecoveryModal,
    setIsOpenAccountRecoveryModal,
    loginFormErrors,
    isOpenRecoveryPasswordModal,
    setIsOpenRecoveryPasswordModal,
    loginPasswordVisible,
    setLoginPasswordVisible,
    isPending,
    titlePasswordRecoveryModal,
    setTitlePasswordRecoveryModal,
    agreementModalOpen,
    setAgreementModalOpen,
    concretizeLogin,
    concretizeLoginProps,
    setConcretizeLoginProps,
    handleChangeLoginInput,
    redirectUri,
    lifeProofModal,
    setLifeProofModal,
    setIsRegistrationInconsistenciesModal,
    isRegistrationInconsistenciesModal
  } = useFormLogin()

  const renderErrorLoginMessage = useCallback(() => {
    switch (loginFormErrors.errorFunction) {
      case 'inactiveAccount':
        return (
          <>
            <Errors.ErrorMessage>
              Olá ninja!{' '}
              <Errors.ErrorClickHere
                openModal={() => setIsOpenAccountRecoveryModal(true)}
              >
                Clique aqui
              </Errors.ErrorClickHere>{' '}
              para acessar sua conta.
            </Errors.ErrorMessage>
          </>
        )

      case 'lifeProof':
        return (
          <Errors.ErrorMessage>
            Olá ninja!{' '}
            <Errors.ErrorHyperLink link={loginFormErrors.errorLink}>
              Clique aqui
            </Errors.ErrorHyperLink>{' '}
            para validar a titularidade da conta.{' '}
          </Errors.ErrorMessage>
        )

      default:
        return (
          <Errors.ErrorMessage>
            {loginFormErrors.errorMessage}
          </Errors.ErrorMessage>
        )
    }
  }, [loginFormErrors])

  const renderButtonLabel = useMemo(() => {
    if (isPending) {
      return <KLoader />
    }

    return (
      <div className='mr-8'>
        <IconLoginButton />
        <span className='ml-8'>ENTRAR</span>
      </div>
    )
  }, [isPending])

  return (
    <section className='w-full flex justify-center desktop:order-2'>
      <div className='w-full max-w-[352px] flex flex-col items-center px-16 mt-24 overflow-hidden '>
        <TmxProfile
          isBrowser={typeof window !== 'undefined'}
          orgId={TMX_COMPANY_ID}
        />

        <Heading color='secondary'>ACESSE SUA CONTA</Heading>

        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='w-full max-w-[384px]'
        >
          <Controller
            name='login'
            control={form.control}
            render={({ field }) => (
              <div className='flex flex-col relative'>
                <span className='text-sm text-black-600'>
                  E-mail, CPF ou CNPJ
                </span>
                <div className='w-32 h-32 absolute left-16 top-32'>
                  <IconUser height='16px' width='16px' />
                </div>
                <input
                  {...field}
                  className={
                    form.formState.errors.login
                      ? inputErrorStyles({})
                      : inputLoginFormStyles({})
                  }
                  type='text'
                  data-testid='login-input'
                  onChange={(event) => {
                    field.onChange(event)
                    handleChangeLoginInput(event)
                  }}
                />
                <span className='text-sm text-error-500 font-medium'>
                  {form.formState.errors.login?.message}
                </span>
              </div>
            )}
          />

          <Controller
            name='password'
            control={form.control}
            render={({ field }) => (
              <div className='flex flex-col relative'>
                <span className='text-sm text-black-600'>Senha</span>

                <div className='w-32 h-32 absolute left-16 top-32'>
                  <IconLocked height='16px' width='16px' />
                </div>

                <input
                  className={
                    form.formState.errors.password
                      ? inputErrorStyles({})
                      : inputLoginFormStyles({})
                  }
                  type={loginPasswordVisible ? 'text' : 'password'}
                  data-testid='password-input'
                  {...field}
                />
                <span className=' text-error-500 text-sm font-medium'>
                  {form.formState.errors.password?.message}
                </span>

                <button
                  type='button'
                  className='w-32 h-32 absolute right-16 top-[30px]'
                  onClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                >
                  {loginPasswordVisible ? (
                    <IconEye
                      width='14'
                      height='14'
                      color={theme.colors.black(700)}
                    />
                  ) : (
                    <IconEyeClose
                      width='14'
                      height='14'
                      color={theme.colors.black(700)}
                    />
                  )}
                </button>
              </div>
            )}
          />

          {loginFormErrors.loginType === 'form' && renderErrorLoginMessage()}

          <button
            className='font-poppins w-full h-48 mt-16 rounded-4 bg-secondary-500 flex items-center justify-center text-white-500 font-bold text-sm cursor-pointer disabled:bg-black-400 disabled:cursor-not-allowed'
            type='submit'
            disabled={
              !!form.formState.errors?.login ||
              !!form.formState.errors?.password ||
              isPending
            }
            data-testid='login-submit-button'
          >
            {renderButtonLabel}
          </button>

          {!isOpenAccountRecoveryModal && (
            <button
              onClick={() => setIsOpenRecoveryPasswordModal(true)}
              className='flex justify-end underline w-full my-8 text-xs text-black-600 font-semibold'
              type='button'
            >
              Esqueceu a senha?
            </button>
          )}
        </form>

        <LoginWithSocialAccount
          concretizeLogin={concretizeLogin}
          setAgreementModalOpen={setAgreementModalOpen}
          setConcretizeLoginProps={setConcretizeLoginProps}
        />
        {loginFormErrors.loginType === 'social' && renderErrorLoginMessage()}

        <div className='flex justify-center items-center gap-4'>
          <span className='text-sm font-normal text-black-700'>
            Novo no KaBuM!
          </span>
          <Link
            href={`/cadastro${redirectUri}`}
            className='text-sm font-normal text-secondary-500 underline tablet:text-base'
            prefetch
          >
            CADASTRE-SE
          </Link>
        </div>
      </div>

      <Modal
        isVisible={isOpenAccountRecoveryModal}
        onCloseButton={() => setIsOpenAccountRecoveryModal(false)}
      >
        <AccountRecoveryModal
          customerEmail={loginFormErrors.userLogin}
          customerPhone={loginFormErrors.userPhone}
          hash={loginFormErrors.recoveryHashLogin}
        />
      </Modal>

      <Modal
        title={titlePasswordRecoveryModal}
        isVisible={isOpenRecoveryPasswordModal}
        onCloseButton={() => {
          setIsOpenRecoveryPasswordModal(false)
          setTitlePasswordRecoveryModal('ESQUECEU SUA SENHA DE ACESSO?')
        }}
      >
        <PasswordRecoveryModal
          setTitlePasswordRecoveryModal={setTitlePasswordRecoveryModal}
          setIsOpenRecoveryPasswordModal={setIsOpenRecoveryPasswordModal}
        />
      </Modal>

      <AgreementModal
        isOpen={agreementModalOpen}
        setIsOpen={setAgreementModalOpen}
        concretizeLogin={concretizeLogin}
        concretizeLoginProps={concretizeLoginProps}
      />

      <Modal
        isVisible={lifeProofModal.isOpen}
        onCloseButton={() =>
          setLifeProofModal({
            ...lifeProofModal,
            isOpen: false
          })
        }
      >
        <LifeProofModal
          errorType={lifeProofModal.errorType}
          handleCloseModal={() =>
            setLifeProofModal({
              ...lifeProofModal,
              isOpen: false
            })
          }
          lifeProofURL={lifeProofModal.lifeProofURL}
          storeCode={lifeProofModal.storeCode}
        />
      </Modal>

      <Modal
        isVisible={isRegistrationInconsistenciesModal}
        onCloseButton={() => setIsRegistrationInconsistenciesModal(false)}
      >
        <RegistrationInconsistenciesModal
          handleCloseModal={() => setIsRegistrationInconsistenciesModal(false)}
        />
      </Modal>
    </section>
  )
}
