import { tv } from 'tailwind-variants'

export const SocialLoginButton = tv({
  base: 'flex w-full text-base font-bold py-8 border border-solid rounded-4 items-center justify-center',
  variants: {
    typeButton: {
      google: 'text-error-500 border-error-500',
      facebook: 'text-primary-500 border-primary-500 bg-opacity-100',
      apple: 'text-black-900 border-black-900'
    }
  }
})
