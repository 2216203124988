const { formatProductFriendlyName } = require('../utils/friendlyName')

export const listingResponseOferta = (response, request) => {
  const { minDiscount, maxDiscount, minPrice, maxPrice } = request

  const customFilters = response.filtro?.personalizado?.map((item) => ({
    code: Number(item.codigo),
    name: item.nome,
    items: item.itens.map((item) => ({
      code: Number(item.codigo),
      name: item.nome
    }))
  }))

  const brandFilters = response.filtro?.marcas?.map((item) => ({
    code: Number(item.codigo),
    name: item.nome
  }))

  let discountRangeFilters = []
  let selectedDiscountRangeFilters = []
  let priceRangeFilters = []
  let selectedPriceRangeFilters = []

  if (response.filtro) {
    discountRangeFilters = [
      {
        min: response.filtro?.desconto_minimo,
        max: response.filtro?.desconto_maximo
      }
    ]

    if (minDiscount || maxDiscount) {
      selectedDiscountRangeFilters = [
        {
          min: minDiscount,
          max: maxDiscount
        }
      ]
    }

    priceRangeFilters = [
      {
        min: response.filtro?.valor_minimo,
        max: response.filtro?.valor_maximo
      }
    ]

    if (minPrice || maxPrice) {
      selectedPriceRangeFilters = [
        {
          min: minPrice,
          max: maxPrice
        }
      ]
    }
  }

  const getDepartments = () => {
    return response.categorias.map((category) => ({
      name: category.nome,
      slug: category.amigavel,
      code: category.codigo,
      sections: category.secs.map((sec) => ({
        name: sec.nome,
        slug: sec.amigavel,
        code: sec.codigo
      }))
    }))
  }

  const products = response.produtos?.map((i) => ({
    code: i.codigo,
    available: i.disponibilidade || false,
    offerCode: i.oferta_codigo,
    name: i.produto,
    friendlyName: formatProductFriendlyName(i.produto),
    sellerName: i.seller_name || 'KaBuM!',
    sellerId: i.vendedor_codigo || 0,
    category: `${i.dep_nome}/${i.sec_nome}` || '',
    manufacturer: i.fabricante_nome || '',
    image: i.imagem,
    oldPrice: i.vlr_normal || 0,
    price: i.vlr_normal,
    priceWithDiscount: i.vlr_oferta || 0,
    primePrice: i.preco_prime || 0,
    primePriceWithDiscount: i.preco_desconto_prime || 0,
    oldPrimePrice: i.preco_antigo_prime || 0, // Não vem
    offerDiscount: i.desconto || 0,
    offerDiscountPrime: i.desconto_prime || 0,
    quantity: i.quantidade || 0,
    rating: i.tmp_avaliacao_nota,
    ratingCount: i?.tmp_avaliacao_numero || 0,
    hashCode: i.di,
    flags: {
      openbox: i.openbox || false,
      freeShipping: i.frete_gratis || false,
      freeShippingPrime: i.frete_gratis_prime || false
    },
    offerDates: {
      startDate: i.data_ini || '',
      endDate: i.data_fim || ''
    },
    colors: {
      main: response.oferta?.cor?.primaria || '',
      text: response.oferta?.cor?.letra || '#FFFFFF'
    },
    offerInfo: {
      mainColor: response.oferta?.cor?.primaria,
      textColor: response.oferta?.cor?.letra || '#FFFFFF',
      startDate: i.data_ini || '',
      endDate: i.data_fim || ''
    },
    tagCode: i.tag_code || 0
  }))

  const pagination = {
    prev: response.paginacao?.pagina_anterior || 1,
    current: response.paginacao?.pagina_atual,
    next: response.paginacao?.proxima_pagina,
    total: response.paginacao?.paginas
  }

  const offerInfo = {
    name: response.oferta.nome,
    mainColor: response.oferta?.cor?.primaria,
    textColor: response.oferta?.cor?.letra || '#FFFFFF',
    startDate: response.oferta?.data_inicio || '',
    endDate: response.oferta?.data_fim || ''
  }

  return {
    listagem: products,
    offerInfo,
    pagination,
    success: response.produtos?.length > 0,
    breadcrumb: {},
    amount: response.quant_ofertas,
    filters: {
      departments: getDepartments() || [],
      custom: customFilters || [],
      brand: brandFilters || [],
      discountRange: discountRangeFilters || [],
      selectedDiscountRange: selectedDiscountRangeFilters || [],
      priceRange: priceRangeFilters || [],
      selectedPriceRange: selectedPriceRangeFilters || []
    }
  }
}

export const listingResponseBannerOferta = (response) => {
  const { banner } = response
  const url = banner.img || banner.imagem

  return {
    error: false,
    url
  }
}
