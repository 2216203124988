import { IconSmartPhoneChecked } from '@k_frontend/icons'
import { KLoader } from 'components/Loader'
import { useMemo } from 'react'
import { MethodSenderMfaCodeEnum } from '../../AccountRecoveryModal.types'
import { SmsSenderButtonProps } from './SmsSenderButton.types'

export function SmsSenderButton({
  sendMfaMethod,
  customerPhone,
  disableSmsButton,
  isLoading
}: SmsSenderButtonProps) {
  const renderSmsButtonMessage = useMemo(() => {
    if (isLoading) {
      return (
        <div className='flex justify-center items-center'>
          <KLoader />
        </div>
      )
    }

    if (!customerPhone) {
      return (
        <span className='text-xs font-normal text-black-600'>
          Você não tem um número cadastrado.
        </span>
      )
    }

    if (disableSmsButton && customerPhone) {
      return (
        <span className='text-xs font-normal text-black-600'>
          Método desabilitado por excesso de tentativas.
        </span>
      )
    }

    return (
      <span className='text-xs font-normal text-black-800'>
        Enviaremos um código para o número:
        <br />
        <b className='text-xs  text-black-800 font-semibold'>{customerPhone}</b>
      </span>
    )
  }, [disableSmsButton, customerPhone, isLoading])

  const SvgColor = useMemo(() => {
    if (disableSmsButton || isLoading) {
      return '#dee0e4'
    }
    return '#ff6500'
  }, [disableSmsButton, isLoading])

  const textColor = useMemo(() => {
    if (disableSmsButton || isLoading) {
      return 'text-black-600'
    }
    return 'text-black-800'
  }, [disableSmsButton, isLoading])

  const isDisableButton = useMemo(() => {
    return disableSmsButton || isLoading
  }, [disableSmsButton, isLoading])

  return (
    <div className='flex w-full justify-start'>
      <button
        type='button'
        className='flex flex-col items-center rounded-4 p-12 border-2 border-black-500 border-solid disabled:bg-black-400 disabled:cursor-not-allowed relative min-w-[160px] max-w-[224px]'
        onClick={() => sendMfaMethod(MethodSenderMfaCodeEnum.SMS)}
        disabled={isDisableButton}
        data-testid='sms-method-button'
      >
        <IconSmartPhoneChecked width='24' height='24' color={SvgColor} />
        <span className={`text-sm font-semibold my-8 ${textColor}`}>SMS</span>
        {renderSmsButtonMessage}
      </button>
    </div>
  )
}
