import { useGoogleLogin } from '@react-oauth/google'
import { useCallback, useEffect } from 'react'

import { useVisitor } from '@k_frontend/core'
import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError
} from 'errors'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useRouter } from 'next/router'
import { useToast } from 'providers/Toast'
import { getParamsFromQueryString } from 'services/listing'
import Kernel from 'settings/kernel'
import { handleStore } from 'utils/handleStore'
import { makeSocialLoginRequest } from '../FormLoginContainer.functions'
import { useLoginStore } from '../FormLoginContainer.store'
import { LoginWithSocialAccountHookProps } from './LoginWithSocialAccount.types'

export function useLoginWithSocialAccount({
  concretizeLogin,
  setAgreementModalOpen,
  setConcretizeLoginProps
}: LoginWithSocialAccountHookProps) {
  const { init } = useVisitor()
  const { showError } = useToast()
  const {
    setIsOpenAccountRecoveryModal,
    loginFormErrors,
    setLoginFormErrors,
    appleAccessToken,
    setAppleAccessToken,
    setLifeProofModal,
    setTypeLogin
  } = useLoginStore()
  const router = useRouter()
  const { loaded } = useReCaptcha()

  const { mutateAsync } = makeSocialLoginRequest()

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const {
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData,
          updateConsentForm,
          customerEmail
        } = await mutateAsync({
          socialNetwork: 'google',
          tokenNetwork: codeResponse.access_token
        })

        if (customerId) {
          await init(Kernel, customerId, customerSession)
        }

        setTypeLogin('google')

        if (!updateConsentForm)
          return concretizeLogin({
            customerId,
            customerSession,
            firstName,
            isPrimeCustomer,
            maxAgeCookie,
            needToUpdateData
          })

        setConcretizeLoginProps({
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData,
          customerEmail
        })
        setAgreementModalOpen(true)
      } catch (error) {
        if (error instanceof ExpectedError) {
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: false,
            errorFunction: 'genericError',
            loginType: 'form'
          })

          showError(error.message)
        }

        if (error instanceof LifeProofError) {
          setLifeProofModal({
            errorType: error.errorType,
            isOpen: true,
            lifeProofURL: error.urlLinkLifeProof,
            storeCode: handleStore()
          })
        }

        if (error instanceof RecoveryAccountError) {
          setLoginFormErrors({
            ...loginFormErrors,
            userLogin: error.email,
            userPhone: error.mobilePhone,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'inactiveAccount',
            recoveryHashLogin: error.recoveryHash,
            loginType: 'form'
          })

          setIsOpenAccountRecoveryModal(true)
          return
        }

        if (error instanceof NonExistentRegistration) {
          showError(error.message)

          setTimeout(() => {
            router.push('/cadastro')
          }, 3000)
        }
      }
    },
    onError: () =>
      showError(
        'Ocorreu um erro ao tentar acessar sua conta KaBuM!, com sua conta Google'
      )
  })

  const callbackSuccessApple = useCallback(async () => {
    try {
      const {
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        updateConsentForm,
        customerEmail
      } = await mutateAsync({
        socialNetwork: 'apple',
        tokenNetwork: appleAccessToken
      })

      if (customerId) {
        await init(Kernel, customerId, customerSession)
      }

      setTypeLogin('apple')

      if (!updateConsentForm)
        return concretizeLogin({
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData
        })

      setConcretizeLoginProps({
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        customerEmail
      })
      setAgreementModalOpen(true)
    } catch (error) {
      if (error instanceof ExpectedError) {
        setLoginFormErrors({
          ...loginFormErrors,
          errorMessage: error.message,
          errorClick: false,
          errorFunction: 'genericError',
          loginType: 'form'
        })

        showError(error.message)
      }

      if (error instanceof LifeProofError) {
        setLifeProofModal({
          errorType: error.errorType,
          isOpen: true,
          lifeProofURL: error.urlLinkLifeProof,
          storeCode: handleStore()
        })
      }

      if (error instanceof RecoveryAccountError) {
        setLoginFormErrors({
          ...loginFormErrors,
          userLogin: error.email,
          userPhone: error.mobilePhone,
          errorMessage: error.message,
          errorClick: true,
          errorFunction: 'inactiveAccount',
          recoveryHashLogin: error.recoveryHash,
          loginType: 'form'
        })

        setIsOpenAccountRecoveryModal(true)
        return
      }

      if (error instanceof NonExistentRegistration) {
        showError(error.message)

        setTimeout(() => {
          router.push('/cadastro')
        }, 3000)
      }
    }
  }, [appleAccessToken])

  useEffect(() => {
    const { access_token, scope } = getParamsFromQueryString(
      window.location.search
    )

    if (scope === 'login_apple' && access_token) {
      setAppleAccessToken(access_token)
      callbackSuccessApple()
    }
  }, [appleAccessToken === undefined || appleAccessToken === '', loaded])

  return {
    googleLogin
  }
}
