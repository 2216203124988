import { HttpAxiosClient } from 'adapters/httpAxiosClient/httpAxiosClient'
import { ExpectedError } from 'errors'
import { CUSTOMER } from 'settings/endpoints'
import { handleStore } from 'utils/handleStore'
import {
  putUserAgreementProps,
  putUserAgreementResponse
} from './agreementAds.types'

export class AgreementAds {
  constructor(public readonly httpClient = new HttpAxiosClient()) {}

  async putUserAgreement({
    customerId,
    customerSession,
    newsletter,
    mfa
  }: putUserAgreementProps): Promise<putUserAgreementResponse> {
    try {
      const { body } = await this.httpClient.request({
        method: 'put',
        url: CUSTOMER.AGREEMENT_ADS,
        body: {
          store_code: handleStore(),
          customer_id: customerId,
          customer_session: customerSession,
          newsletter
        },
        headers: {
          Mfa: mfa || ''
        }
      })

      return {
        message: body.message
      }
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }
}
