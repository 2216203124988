import { NextRouter } from 'next/router'

import { dataLayerEventHotsiteCard } from '@k_frontend/core'
import Kernel from 'settings/kernel'
import { redirectToPage } from 'utils/redirectToPage'

export function handleRedirectUri(
  redirectUri: string,
  router: NextRouter
): void {
  const redirectUriDecoded = decodeURIComponent(redirectUri)

  if (redirectUriDecoded.includes('/cartao_solicitacao')) {
    dataLayerEventHotsiteCard('login-my-account')
  }

  if (
    redirectUri &&
    redirectUriDecoded.startsWith(Kernel.KABUM_COMPLETE_URL) &&
    !redirectUriDecoded.includes('ninjaescape')
  ) {
    router.push(redirectUriDecoded)
  } else {
    redirectToPage('/')
  }
}
