import { IconLocked } from '@k_frontend/icons'
import { Dialog, TextField } from '@k_frontend/ui'
import CheckBoxInput from 'components/Checkbox'
import { StandardButton } from 'components/StandardButton'
import { useAgreementModal } from './AgreementModal.hook'

import { useMemo } from 'react'
import { maskedEmail } from 'utils/maskData'
import * as S from './AgreementModal.styles'
import { AgreementModalProps } from './AgreementModal.types'

export function AgreementModal({
  isOpen,
  setIsOpen,
  concretizeLogin,
  concretizeLoginProps
}: AgreementModalProps) {
  const {
    isChecked,
    onChangeCheckbox,
    counter,
    generateMfaCode,
    shouldDisableSubmitButton,
    onChangeMfaInput,
    putUserAgreement,
    shouldLoadingSubmitButton,
    shouldDisableresendMFAButton
  } = useAgreementModal({
    isOpen,
    setIsOpen,
    concretizeLogin,
    concretizeLoginProps
  })

  const resendButton = useMemo(() => {
    return (
      <button
        type='button'
        className={S.resendEmail()}
        onClick={generateMfaCode}
        data-testid='resend-mfa-code'
        disabled={counter > 0}
      >
        REENVIAR CÓDIGO {counter > 0 && counter}
      </button>
    )
  }, [counter, generateMfaCode, shouldDisableresendMFAButton])

  return (
    <Dialog.Dialog
      open={isOpen}
      customWidth='800px'
      customPadding='24px'
      closeSize={0}
    >
      <div className={S.container()}>
        <IconLocked className={S.svg()} />
        <h1 className={S.title()}>ACESSE SUA CONTA</h1>
        <p className={S.description()}>
          Para realizar o acesso da sua conta, enviamos um e-mail com um código
          de confirmação para o e-mail cadastrado:{' '}
          <strong>{maskedEmail(concretizeLoginProps?.customerEmail)}</strong>.
        </p>
        <TextField
          label='Código de confirmação'
          maxLength={6}
          onChange={onChangeMfaInput}
        />
        <div className={S.row()}>
          <CheckBoxInput checked={isChecked} onChange={onChangeCheckbox} />{' '}
          <p className={S.agreeText()}>
            Quero receber ofertas e novidades por e-mail, SMS, WhatsApp ou
            mensagens nos apps KaBuM!
          </p>
        </div>
        <StandardButton
          isLoading={shouldLoadingSubmitButton}
          disabled={shouldDisableSubmitButton}
          className={S.buttonStyles()}
          onClick={() => putUserAgreement()}
        >
          CONFIRMAR
        </StandardButton>
        {resendButton}
      </div>
    </Dialog.Dialog>
  )
}
