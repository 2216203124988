import { PropsWithChildren } from 'react'
import { ErrorClickHereProps, ErrorHyperLinkProps } from './Errors.types'

const ErrorMessage = ({ children }: PropsWithChildren) => {
  return (
    <p className='font-poppins text-wrap text-error-500 text-sm font-normal mt-8'>
      {children}
    </p>
  )
}

const ErrorClickHere = ({ children, openModal }: ErrorClickHereProps) => {
  return (
    <button
      className='font-poppins text-wrap text-error-500 text-sm font-medium cursor-pointer'
      onClick={openModal}
      type='button'
      data-modal-target='default-modal'
      data-modal-toggle='default-modal'
    >
      {children}
    </button>
  )
}

const ErrorHyperLink = ({ children, link }: ErrorHyperLinkProps) => {
  return (
    <a
      className='font-poppins text-wrap text-error-500 text-sm font-medium cursor-pointer'
      href={link}
      target='_blank'
      rel='noreferrer'
      key='link'
    >
      {children}
    </a>
  )
}

export const Errors = {
  ErrorMessage,
  ErrorClickHere,
  ErrorHyperLink
}
