import { IconCheck } from '@k_frontend/icons'
import { KLoader } from 'components/Loader'
import { useFormLogin, useLoginStore } from 'containers/FormLoginContainer'
import { useMemo } from 'react'

export function SuccessStep() {
  const { onSubmit } = useFormLogin()
  const { login, password, buttonIsLoading } = useLoginStore()
  const loginInputs = {
    login,
    password
  }

  const renderButtonText = useMemo(() => {
    if (buttonIsLoading) {
      return <KLoader />
    }

    return 'CONTINUAR'
  }, [buttonIsLoading])

  return (
    <div className='flex flex-col items-center'>
      <IconCheck width='32' height='32' color='#ff6500' />

      <h1 className='text-sm font-bold text-black-800 uppercase mt-8 mb-16'>
        Acesso liberado!
      </h1>

      <p className='text-xs font-normal text-black-800'>
        Agora você já pode continuar a sua navegação e aproveitar todas as
        nossas ofertas ninjas.
      </p>

      <button
        className='w-full h-48 mt-16 rounded-4 bg-secondary-500 flex items-center justify-center text-white-500 font-bold text-sm cursor-pointer disabled:bg-black-400 disabled:cursor-not-allowed'
        type='button'
        onClick={() => onSubmit(loginInputs)}
        disabled={buttonIsLoading}
      >
        {renderButtonText}
      </button>
    </div>
  )
}
