import * as S from './Heading.styles'
import { HeadingProps } from './Heading.types'

export function Heading({
  children,
  color = 'white',
  lineLeft = false,
  lineBottom = false
}: HeadingProps) {
  return (
    <S.Wrapper
      className='heading mb-24'
      color={color}
      lineLeft={lineLeft}
      lineBottom={lineBottom}
    >
      {children}
    </S.Wrapper>
  )
}
