import { IconCheckbox, IconInputError } from '@k_frontend/icons'

import * as S from './Checkbox.styles'
import { CheckboxProps } from './Checkbox.types'

export default function CheckBoxInput({
  checked,
  label,
  error,
  errorMessage,
  name,
  onChange
}: CheckboxProps) {
  const showErrorMessage = error && errorMessage && errorMessage?.length > 0

  return (
    <>
      <S.Container>
        <S.CheckBox>
          <input
            type='checkbox'
            checked={checked}
            name={name}
            onChange={onChange}
          />
          <div className='inputMark'>
            <IconCheckbox />
          </div>
        </S.CheckBox>
        <S.Title>{label}</S.Title>
      </S.Container>
      {showErrorMessage && (
        <S.ErrorLegend>
          <IconInputError />
          {errorMessage}
        </S.ErrorLegend>
      )}
    </>
  )
}
