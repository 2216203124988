import { ExpectedError } from 'errors'
import { useToast } from 'providers/Toast'
import { useCallback, useState } from 'react'
import { ONE_MINUTE_IN_SECONDS } from 'settings/constants'
import { countdown } from 'utils/countdown'
import { UseValidationCodeStepProps } from './ValidationCodeStep.types'

export function useValidationCodeStep({
  setMfaConfirmationCode,
  onResentButtonAction,
  senderMethodChoosed
}: UseValidationCodeStepProps) {
  const { counter, resetCountdown } = countdown(ONE_MINUTE_IN_SECONDS)
  const [buttonEnable, setButtonEnable] = useState(true)
  const [resendMfaCodeIsLoading, setResendMfaCodeIsLoading] = useState(false)
  const { showError, showSuccess } = useToast()

  const onChangeConfirmationCodeInput = useCallback(
    (confirmationCode: string) => {
      if (confirmationCode.length < 6) {
        setButtonEnable(true)
      }

      if (confirmationCode.length === 6) {
        setMfaConfirmationCode(confirmationCode)
        setButtonEnable(false)
      }
    },
    [setButtonEnable, setMfaConfirmationCode]
  )

  const resendMfaCode = useCallback(async () => {
    setResendMfaCodeIsLoading(true)
    try {
      const data = await onResentButtonAction(senderMethodChoosed)
      showSuccess(data.message)
    } catch (error) {
      if (error instanceof ExpectedError) {
        showError(error.message)
      }
    } finally {
      setResendMfaCodeIsLoading(false)
      resetCountdown()
    }
  }, [])

  return {
    buttonEnable,
    onChangeConfirmationCodeInput,
    counter,
    resetCountdown,
    resendMfaCode,
    resendMfaCodeIsLoading
  }
}
