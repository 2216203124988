import { tv } from 'tailwind-variants'

export const documentStepStyles = tv({
  base: [
    'font-poppins',
    'border',
    'border-solid',
    'border-black-500',
    'rounded-4',
    'p-12',
    'text-black-700',
    'placeholder:text-black-600'
  ]
})

export const documentStepErrorStyles = tv({
  extend: documentStepStyles,
  base: ['border-error-500']
})
