import { ReCaptchaProvider } from 'next-recaptcha-v3'
import { PropsWithChildren } from 'react'
import { CAPTCHA_V3_PUBLIC_KEY } from 'settings/kernel'

export function RecaptchaProvider({ children }: PropsWithChildren) {
  return (
    <ReCaptchaProvider
      reCaptchaKey={CAPTCHA_V3_PUBLIC_KEY}
      useEnterprise
      language='pt-BR'
    >
      {children}
      <div className='text-center text-xs text-black-600 mt-16 px-16 tablet:hidden'>
        Esse site é protegido pelo reCAPTCHA e pela
        <a
          className='text-xs text-secondary-500 ml-4 no-underline'
          href='https://policies.google.com/privacy'
        >
          Política de Privacidade do Google
        </a>{' '}
        e aplicam-se os
        <a
          className='text-xs text-secondary-500 ml-4 no-underline'
          href='https://policies.google.com/terms'
        >
          Termos de Serviço
        </a>
        .
      </div>
    </ReCaptchaProvider>
  )
}
