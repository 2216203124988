import styled, { keyframes } from 'styled-components'
import { font, toRem } from 'theme/utils'

const spinner = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

export const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 60px;
  height: 46px;
  top: 4px;
  right: 5px;

  div {
    transform-origin: 40px 20px;
    animation: ${spinner} 1.2s linear infinite;
  }

  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 6px;
    left: 38px;
    width: 3px;
    height: 7px;
    border-radius: 20%;
    background: ${({ color }) => (color ? color : '#fff')};
  }

  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -1s;
  }

  div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.9s;
  }

  div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.8s;
  }

  div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
  }

  div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
  }

  div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
  }

  div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
  }
`

const loader = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  25% {
    transform: translateX(200px);
    opacity: 0;
  }

  30% {
    transform: translateX(-200px);
    opacity: 0;
  }

  55% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`

const spin = keyframes`
0% {
  transform: rotate(0deg)
}

100% {
  transform: rotate(360deg)
}
`

const blink = keyframes`
  0% {
    opacity: .3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .3;
  }
`

export const LineLoader = styled.div`
  width: ${(props) => toRem(props.size)};
  height: ${(props) => toRem(props.size)};

  background: transparent;
  border: ${(props) => toRem(props.thickness)} solid ${(props) => props.color}55;
  border-top-color: ${(props) => props.color};
  border-radius: 50%;

  animation: ${spin} 0.6s linear infinite;
`

export const KLoader = styled.svg`
  width: ${(props) => `${props.size}px`};

  #kloader1 {
    animation: ${loader} 1.5s ease-in-out infinite;
    animation-delay: 0.2s;
  }

  #kloader2 {
    animation: ${loader} 1.5s ease-in-out infinite;
  }
`

export const KLoaderText = styled.span`
  ${font(14, 20, 400)};

  animation: ${blink} 1.5s linear infinite;
`
