import { IconLocked } from '@k_frontend/icons'
import { shallow } from 'zustand/shallow'
import { useAccountRecoveryModalStore } from '../AccountRecoveryModal.store'
import { ChoiceStepProps } from './ChoiceStep.types'
import { EmailSenderButton } from './EmailSenderButton'
import { SmsSenderButton } from './SmsSenderButton'
export function ChoiceStep({
  customerEmail,
  customerPhone,
  sendMfaMethod
}: ChoiceStepProps) {
  const {
    enableEmailButton,
    enableSmsButton,
    buttonEmailIsLoading,
    buttonSmsIsLoading
  } = useAccountRecoveryModalStore(
    (state) => ({
      enableEmailButton: state.enableEmailButton,
      enableSmsButton: state.enableSmsButton,
      buttonEmailIsLoading: state.buttonEmailIsLoading,
      buttonSmsIsLoading: state.buttonSmsIsLoading
    }),
    shallow
  )

  return (
    <div>
      <IconLocked width='32' height='32' color='#ff6500' />

      <h1 className='text-base font-bold text-black-800 uppercase mt-8 mb-16 desktop:text-2xl'>
        Acesse sua conta
      </h1>

      <p className='text-xs font-normal text-black-800'>
        Para você acessar sua conta, enviaremos um código para seu celular ou
        e-mail cadastrado, selecione a opção desejada:
      </p>

      <div className='grid gap-8 mt-16 grid-cols-2 items-center'>
        <EmailSenderButton
          sendMfaMethod={sendMfaMethod}
          customerEmail={customerEmail}
          disableEmailButton={enableEmailButton}
          isLoading={buttonEmailIsLoading}
        />
        <SmsSenderButton
          sendMfaMethod={sendMfaMethod}
          customerPhone={customerPhone}
          disableSmsButton={enableSmsButton}
          isLoading={buttonSmsIsLoading}
        />
      </div>
    </div>
  )
}
