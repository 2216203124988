import { IconCheck } from '@k_frontend/icons'
import { useMemo } from 'react'
import { SuccessStepProps } from './SuccessStep.types'

export function SuccessStep({
  customerEmail,
  customerPhone,
  methodUsed,
  setIsOpenRecoveryPasswordModal
}: SuccessStepProps) {
  const renderMessage = useMemo(() => {
    if (methodUsed === 1) {
      return (
        <>
          As Informações para &quot;Login&quot; foram enviadas no e-mail{' '}
          <b className='text-sm font-bold text-black-700'>{`${customerEmail}`}</b>
          .<br /> Siga as instruções enviadas para finalizar a recuperação.
        </>
      )
    }

    if (methodUsed === 2) {
      return (
        <>
          As Informações para &quot;Login&quot; foram enviadas para o celular{' '}
          <b className='text-sm font-bold text-black-700'>{`${customerPhone}`}</b>
          .<br /> Siga as instruções enviadas para finalizar a recuperação.
        </>
      )
    }
  }, [customerEmail, customerPhone, methodUsed])

  return (
    <div className='flex flex-col items-center'>
      <IconCheck width='32' height='32' color='#ff6500' />

      <h1 className='text-sm font-bold text-black-800 uppercase mt-8 mb-16'>
        RECUPERAÇÃO DE SENHA ENVIADA!
      </h1>

      <p className='text-sm font-normal text-black-800 text-center'>
        {renderMessage}
      </p>

      <button
        className='w-full h-48 mt-16 rounded-4 bg-secondary-500 flex items-center justify-center text-white-500 font-bold text-sm cursor-pointer disabled:bg-black-400 disabled:cursor-not-allowed'
        type='button'
        onClick={() => setIsOpenRecoveryPasswordModal(false)}
      >
        OK
      </button>

      <span className='text-sm font-normal text-black-800 text-center mt-12'>
        Caso não tenha mais acesso a este e-mail, favor entrar em contato com o{' '}
        <b
          onClick={() => window.toggleMessenger()}
          onKeyUp={() => window.toggleMessenger()}
          className='text-sm font-normal text-secondary-500 text-center mt-12'
        >
          atendimento KaBuM!.
        </b>
      </span>
    </div>
  )
}
