import { removePhoneMask } from 'utils/functions'

export function maskedEmail(email) {
  if (!email) return ''
  if (!email.includes('@')) return email

  const ArrayFirstPartOfEmail = email.split('@')[0]?.split('')
  const ArraySecondPartOfEmail = email.split('@')[1]?.split('')

  const part1 =
    ArrayFirstPartOfEmail[0] +
    '...' +
    ArrayFirstPartOfEmail[ArrayFirstPartOfEmail.length - 1]
  const part3 =
    ArraySecondPartOfEmail[0] +
    '...' +
    ArraySecondPartOfEmail[ArraySecondPartOfEmail.length - 3] +
    ArraySecondPartOfEmail[ArraySecondPartOfEmail.length - 2] +
    ArraySecondPartOfEmail[ArraySecondPartOfEmail.length - 1]

  return part1 + '@' + part3
}

export function maskedMobile(phone) {
  if (phone.includes('#')) return phone
  const formatPhone = removePhoneMask(phone)

  const ddd = formatPhone.slice(0, 2)
  const part3 = formatPhone.slice(7, 11)

  return `(${ddd}) #####-${part3}`
}
