import { isDocumentValid } from 'utils/functions'

export function removeCPForCNPJMask(document) {
  if (
    Object.keys(document.replace(/[^0-9]/g, '')).length >= 11 &&
    !document.includes('@')
  ) {
    if (isDocumentValid(document)) return document.replace(/[^0-9]/g, '')
    else return false
  }
  return document
}
