import { KLoader } from 'components/Loader'
import { useMemo } from 'react'
import { useAccountRecoveryModal } from './AccountRecoveryModal.hook'
import {
  AccountRecoveryModalProps,
  RecoveryStepEnum
} from './AccountRecoveryModal.types'
import { ChoiceStep } from './ChoiceStep'
import { SuccessStep } from './SuccessStep'
import { ValidationCodeStep } from './ValidationCodeStep'

export function AccountRecoveryModal({
  customerPhone,
  customerEmail,
  hash
}: AccountRecoveryModalProps) {
  const {
    isLoading,
    accountRecoveryStep,
    chooseMfaMethodSender,
    typeMethodMfaSenderChoosed,
    mfaConfirmationCode,
    setMfaConfirmationCode,
    onClickToActiveAccount
  } = useAccountRecoveryModal({
    hash
  })

  const renderAccountRecoveryStep = useMemo(() => {
    if (isLoading) {
      return (
        <div className='flex justify-center items-center'>
          <KLoader />
        </div>
      )
    }

    if (accountRecoveryStep === RecoveryStepEnum.DOCUMENT_STEP) {
      return (
        <ChoiceStep
          customerPhone={customerPhone}
          customerEmail={customerEmail}
          sendMfaMethod={chooseMfaMethodSender}
        />
      )
    }

    if (accountRecoveryStep === RecoveryStepEnum.METHOD_CHOICE_STEP) {
      return (
        <ValidationCodeStep
          senderMethodChoosed={typeMethodMfaSenderChoosed}
          mfaConfirmationCode={mfaConfirmationCode}
          setMfaConfirmationCode={setMfaConfirmationCode}
          onButtonAction={onClickToActiveAccount}
          onResentButtonAction={chooseMfaMethodSender}
        />
      )
    }

    if (accountRecoveryStep === RecoveryStepEnum.SUCCESS_STEP) {
      return <SuccessStep />
    }
  }, [
    isLoading,
    accountRecoveryStep,
    chooseMfaMethodSender,
    typeMethodMfaSenderChoosed,
    mfaConfirmationCode,
    setMfaConfirmationCode,
    onClickToActiveAccount
  ])

  return (
    <div className='flex flex-col text-center justify-center items-center'>
      {renderAccountRecoveryStep}
    </div>
  )
}
