import { css } from 'styled-components'

type SpacingValuesType =
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
type FontWeightType = 400 | 700
type FontTransformType = 'uppercase' | 'capitalize'

/**
 * @deprecated use the styled components theme instead - props => props.theme.rem()
 */
export const toRem = (value: number): string => {
  const remNumber = Number(value / 16)
  return `${remNumber}rem`
}

const spacingValues = {
  xxxs: toRem(4),
  xxs: toRem(8),
  xs: toRem(12),
  sm: toRem(16),
  md: toRem(24),
  lg: toRem(32),
  xl: toRem(48),
  xxl: toRem(64)
}

/**
 * @deprecated use the styled components theme instead - props => props.theme.spacing.sm (etc...)
 */
export const spacing = (value: SpacingValuesType): string => {
  return spacingValues[value]
}

/**
 * @deprecated use the styled components theme instead - props => props.theme.font(12, 18, 700) (etc...)
 */
export const font = (
  size: number,
  height?: number,
  weight?: FontWeightType,
  transform?: FontTransformType
) => {
  return css`
    font-size: ${toRem(size)};

    ${() =>
      height
        ? css`
            line-height: ${toRem(height)};
          `
        : css`
            line-height: ${toRem(size + size / 2)};
          `}

    ${() =>
      weight
        ? css`
            font-weight: ${weight};
          `
        : ''}

    ${() =>
      transform
        ? css`
            text-transform: ${transform};
          `
        : ''}
  `
}
