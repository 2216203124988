import { HttpAxiosClient } from 'adapters/httpAxiosClient/httpAxiosClient'
import { ExpectedError } from 'errors'
import { NOTIFICATIONS } from 'settings/endpoints'
import {
  FetchAttemptsToSendMfaCodeResponse,
  SendLinkToRecoveryPasswordProps,
  SendLinkToRecoveryPasswordResponse,
  SendMfaCodeActivationProps,
  SendMfaCodeActivationResponse
} from './notifications.types'

export class Notifications {
  constructor(public readonly httpClient = new HttpAxiosClient()) {}

  async FetchAttemptsToSendMfaCode({
    recoveryHash
  }: { recoveryHash: string }): Promise<FetchAttemptsToSendMfaCodeResponse> {
    const urlWithParams = `${NOTIFICATIONS.MFA_TRIES}?recovery_hash=${recoveryHash}`
    try {
      const { body } = await this.httpClient.request({
        method: 'get',
        url: urlWithParams
      })

      return {
        remainingAttempts: body.send_tries.map((tries) => ({
          description: tries.description,
          enabled: tries.enabled,
          method: tries.method
        }))
      }
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }

  async FetchAttemptsToSendRecoveryPasswordMessage({
    customerDocument
  }: {
    customerDocument: string
  }): Promise<FetchAttemptsToSendMfaCodeResponse> {
    const urlWithParams = `${NOTIFICATIONS.MFA_TRIES_RECOVERY_PASSWORD}?customer_cpf_cnpj=${customerDocument}`
    try {
      const { body } = await this.httpClient.request({
        method: 'get',
        url: urlWithParams
      })

      return {
        remainingAttempts: body.send_tries.map((tries) => ({
          description: tries.description,
          enabled: tries.enabled,
          method: tries.method
        }))
      }
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }

  async SendMfaCodeActivation({
    storeCode,
    senderMethod,
    recoveryHash
  }: SendMfaCodeActivationProps): Promise<SendMfaCodeActivationResponse> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: NOTIFICATIONS.ACTIVATION,
        body: {
          store_code: storeCode,
          send_method: senderMethod,
          recovery_hash: recoveryHash
        }
      })

      return {
        message: body.message
      }
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }

  async SendLinkToRecoveryPassword({
    sendMethod,
    customerDocument,
    storeCode
  }: SendLinkToRecoveryPasswordProps): Promise<SendLinkToRecoveryPasswordResponse> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: NOTIFICATIONS.PASSWORD_RECOVERY,
        body: {
          send_method: sendMethod,
          customer_cpf_cnpj: customerDocument,
          store_code: storeCode
        }
      })

      return {
        customerEmail: body.email,
        customerPhone: body.cellphone
      }
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }

  async SendMfaToEmailOrPhone({
    sendMethod,
    customerId,
    customerSession
  }): Promise<string> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: NOTIFICATIONS.SEND_MFA_TO_USER,
        body: {
          send_method: sendMethod,
          customer_id: customerId,
          customer_session: customerSession
        }
      })

      return body.message
    } catch (error) {
      throw new ExpectedError(error.body.message, error.statusCode)
    }
  }
}
