import { NextRouter } from 'next/router'

import { handleRedirectUri } from 'utils/handleRedirectUri'
import { redirectToPage } from 'utils/redirectToPage'

export function redirect(router?: NextRouter) {
  const redirectUri = (router?.query?.redirect_uri as string) || ''

  if (redirectUri) {
    handleRedirectUri(redirectUri, router)
  } else {
    redirectToPage('/')
  }
}
