import { useMutation, useQuery } from '@tanstack/react-query'
import { ExpectedError } from 'errors'
import { useToast } from 'providers/Toast'
import { useCallback, useEffect, useState } from 'react'
import { Account } from 'services/customer/account'
import { Notifications } from 'services/notifications/notifications'
import { handleStore } from 'utils/handleStore'
import { useAccountRecoveryModalStore } from './AccountRecoveryModal.store'
import {
  MethodSenderMfaCodeEnum,
  RecoveryStepEnum
} from './AccountRecoveryModal.types'

export function useAccountRecoveryModal({ hash }: { hash: string }) {
  const {
    setEnableEmailButton,
    setEnableSmsButton,
    setButtonSmsIsLoading,
    setButtonEmailIsLoading
  } = useAccountRecoveryModalStore()
  const { showError, showSuccess } = useToast()

  const [accountRecoveryStep, setAccountRecoveryStep] =
    useState<RecoveryStepEnum>(RecoveryStepEnum.DOCUMENT_STEP)
  const [typeMethodMfaSenderChoosed, setTypeMethodMfaSenderChoosed] =
    useState<MethodSenderMfaCodeEnum>(undefined)
  const [mfaConfirmationCode, setMfaConfirmationCode] =
    useState<string>(undefined)

  // Query used to fetch attempts to send mfa code to customer
  const { data: attemptsData, isLoading } = useQuery({
    queryKey: ['fetchAttempts'],
    queryFn: async () => {
      const serviceNotifications = new Notifications()
      const result = await serviceNotifications.FetchAttemptsToSendMfaCode({
        recoveryHash: hash
      })
      getAttemptsMessageError(result.remainingAttempts)
      return result
    },
    gcTime: 0
  })

  // Mutate used to send to wished method the receive the code
  const { mutateAsync: sendMfaCodeActivation, isPending } = useMutation({
    mutationFn: async ({
      methodChoosed
    }: { methodChoosed: MethodSenderMfaCodeEnum }) => {
      setButtonSmsIsLoading(true)
      setButtonEmailIsLoading(true)

      const serviceNotifications = new Notifications()
      return await serviceNotifications.SendMfaCodeActivation({
        recoveryHash: hash,
        senderMethod: methodChoosed,
        storeCode: handleStore()
      })
    },
    onSuccess(data) {
      if (data.message) {
        setAccountRecoveryStep(RecoveryStepEnum.METHOD_CHOICE_STEP)
        showSuccess(data.message)
      }
      return data.message
    },
    onSettled(data, error) {
      if (data || error) {
        setButtonSmsIsLoading(false)
        setButtonEmailIsLoading(false)
      }
    },
    onError(error) {
      if (error instanceof ExpectedError) {
        showError(error.message)
      }
    }
  })

  // Mutate used to activate account
  const { mutateAsync: postActivateCustomerAccount } = useMutation({
    mutationFn: async () => {
      setButtonSmsIsLoading(true)
      setButtonEmailIsLoading(true)
      const serviceCustomer = new Account()

      return await serviceCustomer.PostActivateCustomerAccount({
        mfaConfirmationCode,
        recoveryHash: hash
      })
    },
    onSuccess(data) {
      if (data.message) {
        setAccountRecoveryStep(RecoveryStepEnum.SUCCESS_STEP)
        showSuccess(data.message)
      }
      setButtonSmsIsLoading(false)
      setButtonEmailIsLoading(false)

      return data.message
    },
    onSettled(data, error) {
      if (data || error) {
        setButtonSmsIsLoading(false)
        setButtonEmailIsLoading(false)
      }
    },
    onError(error) {
      if (error instanceof ExpectedError) {
        showError(error.message)
      }
    }
  })

  const getAttemptsMessageError = (
    attempts: { enabled: boolean; method: number; description: string }[]
  ) => {
    const isValidAttempts = attempts?.some((attempt) => attempt.enabled)
    if (!isValidAttempts) {
      showError(
        'Ops... Você excedeu o número de tentativas, por favor tente novamente mais tarde!'
      )
    }
  }

  const chooseMfaMethodSender = useCallback(
    async (mfaType: MethodSenderMfaCodeEnum) => {
      setTypeMethodMfaSenderChoosed(mfaType)

      return await sendMfaCodeActivation({
        methodChoosed: mfaType
      })
    },
    [sendMfaCodeActivation]
  )

  const onClickToActiveAccount = useCallback(async () => {
    return await postActivateCustomerAccount()
  }, [postActivateCustomerAccount])

  useEffect(() => {
    if (attemptsData) {
      setEnableEmailButton(!attemptsData.remainingAttempts[0].enabled)
      setEnableSmsButton(!attemptsData.remainingAttempts[1].enabled)
    }
  }, [attemptsData, isPending, isLoading])

  return {
    isLoading,
    accountRecoveryStep,
    setAccountRecoveryStep,
    chooseMfaMethodSender,
    typeMethodMfaSenderChoosed,
    mfaConfirmationCode,
    setMfaConfirmationCode,
    onClickToActiveAccount,
    isPending
  }
}
