import { Controller } from 'react-hook-form'
import { cpfOrCpnjMask } from 'utils/functions'
import {
  documentStepErrorStyles,
  documentStepStyles
} from './DocumentStep.styles'
import { DocumentStepProps } from './DocumentStep.types'

export function DocumentStep({
  form,
  shouldDisableDocumentButton
}: DocumentStepProps) {
  return (
    <>
      <Controller
        name='customerDocument'
        control={form.control}
        render={({ field }) => (
          <div className='flex flex-col w-full'>
            <span className='text-sm text-black-800 my-12'>
              Informe seu número do CPF ou CNPJ de cadastro:
            </span>

            <span className='flex font-poppins text-black-600 text-sm'>
              CPF ou CNPJ
            </span>

            <input
              name='customerDocument'
              value={cpfOrCpnjMask(field.value)}
              className={
                form.formState.errors.customerDocument
                  ? documentStepErrorStyles({})
                  : documentStepStyles({})
              }
              type='text'
              maxLength={18}
              {...field}
            />

            <span className='font-poppins text-error-500 text-sm font-medium'>
              {form.formState.errors.customerDocument?.message}
            </span>
          </div>
        )}
      />
      <button
        type='submit'
        className='w-[224px] h-40 bg-secondary-500 text-white-500 text-sm font-bold rounded-4 mt-12 disabled:bg-black-550'
        disabled={shouldDisableDocumentButton}
      >
        ENVIAR
      </button>
    </>
  )
}
