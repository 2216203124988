import { z } from 'zod'
import { passwordRecoveryModalSchema } from './PasswordRecoveryModal.schema'

export type PasswordRecoveryModalValues = z.infer<
  typeof passwordRecoveryModalSchema
>

export enum RecoveryStepEnum {
  DOCUMENT_STEP = 'cpfOrCnpjStep',
  METHOD_CHOICE_STEP = 'choiceStep',
  SUCCESS_STEP = 'successStep',
  LIFE_PROOF_ERROR_STEP = 'lifeProofErrorStep'
}

export enum MethodSenderMfaCodeEnum {
  EMAIL = 1,
  SMS = 2
}

export type UsePasswordRecoveryModalStoreProps = {
  enableEmailButton: boolean
  enableSmsButton: boolean
  buttonIsLoading: boolean
  setEnableEmailButton: (enableEmail: boolean) => void
  setEnableSmsButton: (enableSms: boolean) => void
  setButtonIsLoading: (isLoading: boolean) => void
}
