import { useEffect, useState } from 'react'

import Script from 'next/script'

import { generateSessionID } from 'utils/generateSessionID'
import { saveSessionIdTmxStorage } from 'utils/sessionIdTmxStorage'

type TmxProfileProps = {
  isBrowser: boolean
  orgId: string
}

export function TmxProfile({ isBrowser, orgId }: TmxProfileProps) {
  const [session, setSession] = useState<string>('')

  useEffect(() => {
    const sessionID = generateSessionID()
    saveSessionIdTmxStorage(sessionID)
    setSession(sessionID)
  }, [])

  if (isBrowser && session) {
    return (
      <Script
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          kbmTmx.profile("h.online-metrix.net", '${orgId}', '${session}');
        `
        }}
      />
    )
  }
  return null
}
