import { IconApple, IconGoogle } from '@k_frontend/icons'

import { useServicesStatus } from 'hooks/useServiceStatus'
import { useMemo } from 'react'
import AppleSignIn from 'react-apple-signin-auth'
import { LG } from 'settings/endpoints'
import { APPLE_CLIENT_ID, ENDPOINT_PRI } from 'settings/kernel'
import { useLoginWithSocialAccount } from './LoginWithSocialAccount.hook'
import { SocialLoginButton } from './LoginWithSocialAccount.styles'
import { LoginWithSocialAccountContainerProps } from './LoginWithSocialAccount.types'

export function LoginWithSocialAccount({
  concretizeLogin,
  setAgreementModalOpen,
  setConcretizeLoginProps
}: LoginWithSocialAccountContainerProps) {
  const { googleLogin } = useLoginWithSocialAccount({
    concretizeLogin,
    setAgreementModalOpen,
    setConcretizeLoginProps
  })
  const {
    servicesStatus: { enable_signin_google, enable_signin_apple }
  } = useServicesStatus()

  const renderLoginWithSocialAccount = useMemo(() => {
    if (!enable_signin_google && !enable_signin_apple) return null

    return (
      <div className='w-full'>
        <div className='w-full flex items-center justify-center relative my-8'>
          <span className='absolute text-sm text-black-600 font-normal px-6 bg-white-500'>
            OU
          </span>
          <div className='w-full h-[1px] bg-black-500'></div>
        </div>

        <span className='flex w-full text-sm text-black-600 font-semibold mt-16 justify-center'>
          Quero acessar com minhas redes sociais
        </span>

        <div className='py-16'>
          {enable_signin_google && (
            <div className='pb-4'>
              <div className='g_id_signin' data-type='standard'>
                <button
                  type='button'
                  className={SocialLoginButton({
                    typeButton: 'google'
                  })}
                  onClick={() => googleLogin()}
                >
                  <IconGoogle width='20' height='20' color='#d5041a' />
                  <span className='ml-8'>Google</span>
                </button>
              </div>
            </div>
          )}
          {enable_signin_apple && (
            <div className='pt-4'>
              <AppleSignIn
                authOptions={{
                  clientId: `${APPLE_CLIENT_ID}`,
                  redirectURI: `${ENDPOINT_PRI}${LG.APPLE_CALLBACK}`,
                  scope: 'email name',
                  nonce: 'nonce',
                  state: '',
                  usePopup: false
                }}
                className='apple-auth-btn'
                render={(props) => (
                  <button
                    type='button'
                    className={SocialLoginButton({
                      typeButton: 'apple'
                    })}
                    onClick={props.onClick}
                    disabled={props.disabled}
                  >
                    <IconApple width='20' height='20' color='#0e1116' />
                    <span className='ml-8'>Apple</span>
                  </button>
                )}
                uiType={'dark'}
                onSuccess={() => console.log()}
                onError={() => console.error()}
              />
            </div>
          )}
        </div>
      </div>
    )
  }, [enable_signin_google, enable_signin_apple])

  return renderLoginWithSocialAccount
}
