import styled from 'styled-components'

export const Container = styled.label`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`

export const CheckBox = styled.label`
  display: flex;
  align-self: center;
  position: relative;
  width: ${({ theme }) => theme.rem(20)};
  height: ${({ theme }) => theme.rem(20)};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.xxs};
  flex-shrink: 0;

  & .inputMark {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: solid 1px ${({ theme }) => theme.colors.black(800)};
  }

  & .inputMark > svg {
    content: '';
    position: absolute;
    display: none;
    width: ${({ theme }) => theme.rem(12)};
    height: ${({ theme }) => theme.rem(12)};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & input:checked ~ .inputMark {
    border-color: ${({ theme }) => theme.colors.secondary(500)};
  }

  & input:checked ~ .inputMark > svg {
    display: block;
  }

  & input:focus ~ .inputMark {
    border-color: ${({ theme }) => theme.colors.secondary(500)};
  }
`

export const Title = styled.p`
  ${({ theme }) => theme.font(10, 16, 400)};
  color: ${({ theme }) => theme.colors.black(600)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${({ theme }) => theme.font(14, 18, 400)};

    a {
      ${({ theme }) => theme.font(14, 18, 400)};
    }
  }
`

export const ErrorLegend = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.error(500)};
  margin-top: ${({ theme }) => theme.spacing.xxs};
  ${({ theme }) => theme.font(14, 14)};

  & svg {
    flex-shrink: 0;
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`
