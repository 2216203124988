import { removeCPForCNPJMask } from 'utils/validadeLoginInput'
import { z } from 'zod'

export const formLoginSchema = z.object({
  login: z
    .string({ required_error: 'Campo é obrigatório.' })
    .refine((login) => {
      return removeCPForCNPJMask(login)
    }, 'E-mail, CPF ou CNPJ informado é invalido.'),
  password: z
    .string({ required_error: 'Campo é obrigatório.' })
    .min(6, `O campo de 'Senha' deve conter ao menos 6 digitos.`)
})
