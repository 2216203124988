import { IconLoad } from '@k_frontend/icons'
import * as S from './StandardButton.styles'
import { StandardButtonProps } from './StandardButton.types'

export function StandardButton({
  children,
  ghost,
  fullWidth,
  isLoading,
  className,
  size,
  ...props
}: StandardButtonProps) {
  return (
    <button
      {...props}
      className={`${S.buttonWrapper({ ghost, fullWidth, size })} ${className}`}
      disabled={props.disabled || isLoading}
    >
      {children} {isLoading && <IconLoad />}
    </button>
  )
}
