import { IconLocked } from '@k_frontend/icons'
import { KLoader } from 'components/Loader'
import { useMemo } from 'react'
import { useAccountRecoveryModalStore } from '../AccountRecoveryModal.store'
import { MethodSenderMfaCodeEnum } from '../AccountRecoveryModal.types'
import { useValidationCodeStep } from './ValidationCodeStep.hook'
import { ValidationCodeStepProps } from './ValidationCodeStep.types'

const ACCOUNT_RECOVERY_TEXT = {
  text: {
    [MethodSenderMfaCodeEnum.EMAIL]:
      'Para realizar o acesso da sua conta, enviamos um código de confirmação para o e-mail',
    [MethodSenderMfaCodeEnum.SMS]:
      'Para realizar o acesso da sua conta, enviamos um código de confirmação para o telefone celular cadastrado:'
  }
}

export function ValidationCodeStep({
  senderMethodChoosed,
  mfaConfirmationCode,
  setMfaConfirmationCode,
  onButtonAction,
  onResentButtonAction
}: ValidationCodeStepProps) {
  const {
    buttonEnable,
    onChangeConfirmationCodeInput,
    counter,
    resetCountdown,
    resendMfaCode,
    resendMfaCodeIsLoading
  } = useValidationCodeStep({
    setMfaConfirmationCode,
    onResentButtonAction,
    senderMethodChoosed
  })
  const { buttonEmailIsLoading, buttonSmsIsLoading } =
    useAccountRecoveryModalStore()

  const renderButtonLabel = useMemo(() => {
    if (buttonEmailIsLoading || buttonSmsIsLoading) {
      return (
        <div className='flex justify-center items-center p-32'>
          <KLoader />
        </div>
      )
    }

    return (
      <div className='mr-8'>
        <span className='ml-8'>CONFIRMAR</span>
      </div>
    )
  }, [senderMethodChoosed, buttonEmailIsLoading, buttonSmsIsLoading])

  const renderCountdownToResendMfaCode = useMemo(() => {
    if (counter > 0) {
      return <>REENVIAR CÓDIGO ({counter})</>
    }

    if (resendMfaCodeIsLoading) {
      return 'REENVIANDO CÓDIGO DE CONFIRMAÇÃO'
    }

    return 'REENVIAR CÓDIGO'
  }, [
    counter,
    resendMfaCodeIsLoading,
    resetCountdown,
    buttonEmailIsLoading,
    buttonSmsIsLoading
  ])

  return (
    <div className='flex flex-col items-center'>
      <IconLocked width='32' height='32' color='#ff6500' />

      <h1 className='text-base font-bold text-black-800 uppercase mt-8 mb-16 desktop:text-2xl'>
        Acesse sua conta
      </h1>

      <p className='text-xs font-normal text-black-800'>
        {ACCOUNT_RECOVERY_TEXT.text[senderMethodChoosed]}
      </p>

      <div className='mt-12'>
        <span className='flex font-poppins text-black-600 text-xs'>
          Código de confirmação
        </span>

        <input
          className='text-sm text-black-600 h-48 w-[320px] border border-solid rounded-4 border-black-600 p-12'
          type='text'
          data-testid='mfa-code-confirmation'
          maxLength={6}
          value={mfaConfirmationCode}
          onChange={(e) =>
            e.target.value.length <= 6 &&
            onChangeConfirmationCodeInput(e.target.value)
          }
        />

        <button
          className='w-full h-48 mt-16 rounded-4 bg-secondary-500 flex items-center justify-center text-white-500 font-bold text-sm cursor-pointer disabled:bg-black-400 disabled:cursor-not-allowed'
          type='button'
          disabled={buttonEnable || buttonEmailIsLoading || buttonSmsIsLoading}
          onClick={onButtonAction}
        >
          {renderButtonLabel}
        </button>

        <button
          className='w-full mt-12 rounded-4 flex items-center justify-center text-black-550 font-semibold text-sm cursor-pointer underline disabled:cursor-not-allowed disabled:no-underline'
          type='button'
          disabled={counter > 0 || resendMfaCodeIsLoading}
          onClick={resendMfaCode}
          data-testid='resend-mfa-code'
        >
          {renderCountdownToResendMfaCode}
        </button>
      </div>
    </div>
  )
}
